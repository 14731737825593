
.App-dz-toolbar {
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.App-dz-gids-container {
    width: 150px;
    height: fit-content;
    min-height: 40px;
}

.App-dz-gids-label {
    font-family: inherit !important;
}

.App-dz-gids-control {
    line-height: 1.5em !important;
}

.App-dz-gids {
    text-align: left !important;
    font-family: inherit !important;
}

.App-dz-gids-menu-option {
    font-family: inherit !important;
}

.App-dz-submit-button-container {
    padding-left: 1vw;
    width: 150px;
    height: fit-content;
    display: grid;
}

.App-dz-submit-button {
    font-family: inherit !important;
    color: rgb(0,0,0,.87) !important;
    border-color: rgb(0,0,0,.35) !important;
    text-transform: none !important;
    min-height: 40px;
    border: 1px solid;
}

.MuiButton-root.Mui-disabled {
    color: rgb(0,0,0,.26) !important;
    border-color: rgb(0,0,0,.26) !important;
}

.App-gids-alert {
    color: white !important;
    font-family: inherit !important;
    min-width: 265px !important;
}
