.contact-us-text {
  text-align: left;
  min-width: inherit;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Source Sans Pro";
  font-size: small;
}

.email {
  font-size: medium;
  color: #0071BC;
}

.page-name {
  text-align: left;
  font-size: x-large;
}

a.home-link {
  font-size: medium;
  color: #0071BC;
}

.ContactUs {
  min-height: 85vh;
}