.Footer {
    min-height: 14vh;
    display: flex;
    text-align: center;
    font-size: xx-small;
    color: #0071BC;
}

.footer-content {
    text-align: center;
    align-self: flex-end;
    min-width: 100%;
}