.accessibility-text {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
  min-width: inherit;
  font-family: "Source Sans Pro";
  font-size: small;
}

.data-link, a.data-link {
  font-family: "Source Sans Pro";
  font-size: small;
  color: #0071BC;
}

.page-name {
  text-align: left;
  padding-left: 20px;
  font-family: "Source Sans Pro";
  font-size: x-large;
}

li {
  margin-left: 2em;
}

a.home-link {
  font-size: small;
  color: #0071BC;
}

.Accessibility {
  min-height: 85vh;
}