.dropzone-container {
    height: 90%;
    min-width: inherit;
    align-self: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
}

.MuiDropzoneArea-icon {
    color: #0071BC !important;
    align-self: center;
}

.MuiDropzoneArea-root {
    height: 90%;
    background-color: rgb(59, 59, 59);
    justify-content: center !important;
    align-items: center; 
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 3vh;
    padding-top: 3vh;
}

.MuiDropzoneArea-textContainer {
    display: flex !important;
    flex-direction:column-reverse !important;
    position: relative;
}

.MuiDropzoneArea-text {
    font-family: inherit !important;
}

.MuiDropzonePreviewList-root {
    padding-top: 3vh;
    align-items: center;
    justify-content: space-evenly; 
}

.MuiDropzonePreviewList-imageContainer {
    padding-top: 3vh;
    max-width: 150px !important;
}

.MuiDropzonePreviewList-removeButton {
    right: -2vw !important;
}

.MuiTypography-body1 {
    min-height: 48px;
    font: inherit !important;
    overflow-wrap: break-word;
} 

