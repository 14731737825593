.Main {
  min-height: 85vh;
  text-align: center;
  min-width: inherit;
}

.main-page-divider {
  width: 90%;
  margin-top: 0;
  margin-bottom: 0;
}