.audit-table-title {
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 0vw;
    font-size: 1.5em;
}

.audit-table-utility-rows {
    padding-bottom: 3vh;
    padding-right: 5vw;
    padding-left: 5vw;
    font-size: 1.5em;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.audit-table-refresh-button {
    font-family: inherit !important;
    color: rgb(0,0,0,.87) !important;
    border-color: rgb(0,0,0,.35) !important;
    text-transform: none !important;
    min-height: 40px;
    width: 20vw;
    min-width: 200px;
    border: 1px solid;
}

.audit-table-refresh-button:disabled {
    font-family: inherit !important;
    text-transform: none !important;
    min-height: 40px;
    border: 1px solid;
}

.audit-table-searchbar {
    font-family: inherit !important;
    min-height: 40px;
    width: 20vw;
    min-width: 200px;
    line-height: 1.5em !important;
}

.audit-table-searchbar * {
    font-family: inherit !important;
}

.audit-table-container {
    align-self: center;
    display: grid !important;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 90%;
    min-height: 90%;
}

.audit-table-header-cell {
    background-color: #0071BC !important;
    color: white !important;
    font-family: inherit !important;
    font-size: 18px !important;
}

.audit-table-data-cell {
    font-family: inherit !important;
    font-size: 16px !important;
}

.validation-status-container {
    display: flex;
    align-items: center;
}

.processing-status-container {
    display: flex;
    align-items: center;
}