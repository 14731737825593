.alert-dialog-container {
    font-family: "Source Sans Pro" !important;
}

.alert-dialog-title {
    align-self: center;
    font-size: 1.5rem !important;
    font-family: inherit !important;
}

.alert-dialog-description {
    align-self: center;
    font-family: inherit !important;
}

.alert-dialog-actions {
    align-self: center;
    font-family: inherit !important;
}

.alert-dialog-button {
    color: white !important;
    background-color: #0071BC !important;
    text-transform: none !important;
    font-size: 1rem !important;
    font-family: inherit !important;
}

