.App-nav-title {
    align-items: center;
    justify-content: space-between;
    display: flex;
    white-space: nowrap;
}

.App-nav-auth {
    align-items: center;
    justify-content: space-between;
    display: flex;
    white-space: nowrap;
    font-size: 1.2em !important;
    line-height: 1.5 !important;
}

.App-nav-auth-user {
    color: #fff;
    margin-left: 24px;
}

.App-nav-auth-divider {
    margin: 0 12px;
}

.App-nav-auth-button {
    padding-bottom: 5px !important;
    font-family: inherit !important;
    text-transform: none !important;
    font-size: large !important;
}

a {
    color: inherit;
    text-decoration: none;
    font-size: 1.6em;
}

a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
}