.container1 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.message {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: #0071BC;
  font-size: 1.2em;
  vertical-align: middle;
}
